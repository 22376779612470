import React, { useState } from "react";
import "./Login.css";
import * as BsIcons from "react-icons/bs";
import nhaLogo from "../dist/img/nha-logo.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import "react-toastify/dist/ReactToastify.css";
const ChangePassword = () => {
    const [employeeNo, setEmployeeNo] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentStep, setCurrentStep] = useState(1);

    const labelStyle = { fontWeight: "normal" };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match.");
            return;
        }
        try {
            const response = await axios.post(
                "https://api.nha.gov.ph/ep/change-password",
                {
                    newPassword,
                },
                { withCredentials: true } // This is the config object
            );
            console.log("resss", response.data);
            if (response.data.stat === "success") {
                toast.success("Password changed successfully.");
            } else {
                toast.error("Failed to change password.");
            }
        } catch (error) {
            console.error("Error changing password:", error);
            toast.error("Error changing password. Please try again.");
        }
    };

    return (
        <>
            <Header />
            <Sidebar />
            <div className="login-page" style={{ backgroundColor: "#f1f1f4" }}>
                <div className="login-box">
                    <div
                        className="card elevation-2"
                        style={{
                            height: "400px",
                            borderTop: "solid 4px #2be0a9",
                            backgroundColor: "#f5f6f8",
                        }}
                    >
                        <div className="card-body mt-2">
                            <div className="text-left mb-4">
                                <small>
                                    <a
                                        href="#"
                                        onClick={() => setCurrentStep(1)}
                                        style={{ color: "#34a8ff" }}
                                    >
                                        <BsIcons.BsArrowLeft
                                            className="mb-1"
                                            style={{ color: "#34a8ff" }}
                                        />{" "}
                                        <b>Back to Login</b>
                                    </a>
                                </small>
                            </div>
                            <div className="text-center">
                                <img
                                    src={nhaLogo}
                                    alt="NHA Logo"
                                    style={{ width: "50px" }}
                                />
                                <h4 className="font-weight-bold mt-3">
                                    Change Password
                                </h4>
                            </div>
                            <form onSubmit={handleChangePassword}>
                                {/* <TextField
                                className="mb-3"
                                label="Employee No."
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={employeeNo}
                                onChange={(e) => setEmployeeNo(e.target.value)}
                                InputLabelProps={{ style: labelStyle }}
                            /> */}
                                {/* <TextField
                                className="mb-3"
                                label="Email Address"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                InputLabelProps={{ style: labelStyle }}
                            /> */}
                                <TextField
                                    className="mb-3"
                                    label="New Password"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    InputLabelProps={{ style: labelStyle }}
                                    type="password"
                                />
                                <TextField
                                    className="mb-3"
                                    label="Confirm New Password"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    InputLabelProps={{ style: labelStyle }}
                                    type="password"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="w-100 mt-4 text-dark"
                                    size="large"
                                    style={{
                                        backgroundColor: "#2be0a9",
                                        borderRadius: "50px",
                                    }}
                                >
                                    Change Password
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
