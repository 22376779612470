import React, { useState } from "react";
import { Card, CardHeader, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

// Styled component for the input
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const BulkUpload = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadSummary, setUploadSummary] = useState(null);
    const [progress, setProgress] = useState(0);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (
            selectedFile &&
            selectedFile.type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            setFile(selectedFile);
            setError(null);
            setUploadSummary(null);
        } else {
            setError("Please select a valid Excel file (.xlsx)");
            setFile(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError("Please select a file to upload");
            return;
        }

        setLoading(true);
        setError(null);
        setProgress(0);

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(
                "https://api.nha.gov.ph/ep/BulkuploadBeneficiaries",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            setUploadSummary(response.data.summary);
        } catch (err) {
            console.error("Upload error:", err.response?.data);
            const errorMessage =
                err.response?.data?.error ||
                err.response?.data?.message ||
                "An error occurred during upload";
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <Sidebar />
            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Bulk Upload Beneficiaries</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="container-fluid">
                        <Card elevation={2} sx={{ borderRadius: "15px" }}>
                            <CardHeader
                                title="Excel Upload"
                                sx={{
                                    borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                            />
                            <CardContent>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}

                                <form onSubmit={handleSubmit}>
                                    <Button
                                        component="label"
                                        variant="contained"
                                        startIcon={<CloudUploadIcon />}
                                        disabled={loading}
                                    >
                                        Select Excel File
                                        <VisuallyHiddenInput
                                            type="file"
                                            accept=".xlsx"
                                            onChange={handleFileChange}
                                        />
                                    </Button>

                                    {file && (
                                        <div style={{ margin: "10px 0" }}>
                                            Selected file: {file.name}
                                        </div>
                                    )}

                                    {loading && (
                                        <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                            sx={{ my: 2 }}
                                        />
                                    )}

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        disabled={loading || !file}
                                        sx={{ mt: 2 }}
                                    >
                                        {loading
                                            ? "Uploading..."
                                            : "Upload File"}
                                    </Button>
                                </form>

                                {uploadSummary && (
                                    <div style={{ marginTop: "24px" }}>
                                        <h4>Upload Summary</h4>
                                        <div style={{ marginTop: "16px" }}>
                                            <p>
                                                Total Rows in File:{" "}
                                                {uploadSummary.totalRowsInFile}
                                            </p>
                                            <p>
                                                Successfully Created:{" "}
                                                {
                                                    uploadSummary
                                                        .processingSummary
                                                        .successfullyCreated
                                                }
                                            </p>
                                            <p>
                                                Failed to Create:{" "}
                                                {
                                                    uploadSummary
                                                        .processingSummary
                                                        .failedToCreate
                                                }
                                            </p>
                                            <p>
                                                Spouses Created:{" "}
                                                {
                                                    uploadSummary
                                                        .processingSummary
                                                        .spousesCreated
                                                }
                                            </p>
                                            <p>
                                                Total Batches:{" "}
                                                {
                                                    uploadSummary
                                                        .processingSummary
                                                        .totalBatches
                                                }
                                            </p>

                                            {uploadSummary.validationErrors
                                                .length > 0 && (
                                                <div>
                                                    <p>
                                                        Validation Errors in
                                                        Rows:{" "}
                                                        {uploadSummary.validationErrors.join(
                                                            ", "
                                                        )}
                                                    </p>
                                                </div>
                                            )}

                                            {uploadSummary.failedRows.length >
                                                0 && (
                                                <div>
                                                    <p>
                                                        Failed Rows:{" "}
                                                        {uploadSummary.failedRows.join(
                                                            ", "
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default BulkUpload;
