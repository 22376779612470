import React, { useEffect } from "react";
import { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import MHQRRS from "./pages/MHQRRS";
import BeneficiaryInfo from "./pages/BeneficiaryInfo";
import Beneficiary from "./pages/Beneficiary";
import Batch from "./pages/Batch";
import RequestTable from "./pages/RequestTable";
import EPstatus from "./pages/EPstatus";
import EPmonitoring from "./pages/EPmonitoring";
import EPDMS from "./pages/EPDMS";
import SummaryReport from "./pages/SummaryReport";
import ResettlementSite from "./pages/MQHRRS2";
import BatchDetails from "./pages/BatchDetails";
import RequestList from "./pages/Request";
import Ep from "./pages/Ep";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/ProtectedRoute";
import AccessDeniedPage from "./components/AccessDenied";
import Cookies from "js-cookie";
import ChangePassword from "./pages/ChangePassword";
import QrDetails from "./pages/QrDetails";
import UploadIssued from "./pages/UploadIssued";
import BulkUpload from "./pages/BulkBeneficiary";
import "./axiosConfig";

function App() {
  return (
    <HashRouter>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="home" element={<Dashboard />} />
        <Route
          path="users"
          element={
            <ProtectedRoute accessLevelRequired={0}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="mqhrrs"
          element={
            <ProtectedRoute accessLevelRequired={2}>
              <MHQRRS />
            </ProtectedRoute>
          }
        />
        <Route path="verify/:controlNumber" element={<QrDetails />} />

        <Route
          path="changepassword"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="uploadissued"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <UploadIssued />
            </ProtectedRoute>
          }
        />
        <Route path="/access-denied" element={<AccessDeniedPage />} />
        <Route
          path="beneficiary"
          element={
            <ProtectedRoute accessLevelRequired={2}>
              <Beneficiary />
            </ProtectedRoute>
          }
        />
        <Route path="bulkupload" element={<BulkUpload />} />
        <Route
          path="beneficiary-info/:id"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <BeneficiaryInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="batch"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <Batch />
            </ProtectedRoute>
          }
        />
        <Route
          path="requesttable"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <RequestTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="epstatus"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <EPstatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="epmonitoring"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <EPmonitoring />
            </ProtectedRoute>
          }
        />
        <Route
          path="epdms"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <EPDMS />
            </ProtectedRoute>
          }
        />
        <Route
          path="summaryreport"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <SummaryReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="request/:requestNumber"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <RequestList />
            </ProtectedRoute>
          }
        />
        <Route
          path="resettlementsite"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <ResettlementSite />
            </ProtectedRoute>
          }
        />
        <Route
          path="ep"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <Ep />
            </ProtectedRoute>
          }
        />
        <Route
          path="batchdetails/:batchNumber"
          element={
            <ProtectedRoute accessLevelRequired={[0, 0.1, 0.2, 1, 1.1, 1.2, 2]}>
              <BatchDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
