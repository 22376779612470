import React, { useEffect, useState } from "react";
import * as BsIcons from "react-icons/bs";
import NHALogo from "../dist/img/nha-logo.png";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

const Sidebar = () => {
    const [accessLevel, setAccessLevel] = useState();
    useEffect(() => {
        const al = Cookies.get("accessLevel");
        setAccessLevel(al);
    }, []);

    return (
        <div>
            <aside
                className="main-sidebar sidebar-dark-light elevation-2 h-100"
                style={{
                    bottom: "0",
                    position: "fixed",
                    backgroundColor: "#292726",
                }}
            >
                <a
                    href="https://entrypass.nha.gov.ph/#/"
                    className="brand-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={NHALogo}
                        alt="NHA Logo"
                        className="brand-image img-circle elevation-2"
                        style={{ opacity: ".8" }}
                    />
                    <span className="brand-text">
                        <b>Entry</b>
                    </span>
                    <span style={{ color: "#33f4a3" }}>
                        <b>Pass</b>
                    </span>
                </a>
                <div className="sidebar">
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="menu"
                            data-accordion="false"
                        >
                            <li className="nav-item">
                                <NavLink
                                    to="/home"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsHouseFill className="nav-icon mb-1" />{" "}
                                    <p>Dashboard</p>
                                </NavLink>
                            </li>
                            {accessLevel == 2 ? (
                                <li className="nav-item mt-2">
                                    <NavLink
                                        to="/mqhrrs"
                                        className="nav-link"
                                        id="btn-sidebar-custom"
                                        style={{ borderRadius: "50px" }}
                                    >
                                        <BsIcons.BsFillPersonBadgeFill className="nav-icon mb-1" />{" "}
                                        <p>MLQH-RRS</p>
                                    </NavLink>
                                </li>
                            ) : (
                                <div></div>
                            )}
                            {accessLevel == 2 ? (
                                <li className="nav-item mt-2">
                                    <NavLink
                                        to="/beneficiary"
                                        className="nav-link"
                                        id="btn-sidebar-custom"
                                        style={{ borderRadius: "50px" }}
                                    >
                                        <BsIcons.BsFolderFill className="nav-icon mb-1" />{" "}
                                        <p>Beneficiary Info</p>
                                    </NavLink>
                                </li>
                            ) : (
                                <div></div>
                            )}
                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/batch"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsBookmarksFill className="nav-icon mb-1" />{" "}
                                    <p>Batch</p>
                                </NavLink>
                            </li>

                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/requesttable"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsFillPenFill className="nav-icon mb-1" />{" "}
                                    <p>Request</p>
                                </NavLink>
                            </li>

                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/epstatus"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsFillBarChartLineFill className="nav-icon mb-1" />{" "}
                                    <p>EntryPass Status</p>
                                </NavLink>
                            </li>

                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/epdms"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsGrid1X2Fill className="nav-icon mb-1" />{" "}
                                    <p>EntryPass DMS</p>
                                </NavLink>
                            </li>

                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/summaryreport"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsFillFileBarGraphFill className="nav-icon mb-1" />{" "}
                                    <p>Summary Report</p>
                                </NavLink>
                            </li>

                            <li className="nav-item mt-2">
                                <NavLink
                                    to="/epmonitoring"
                                    className="nav-link"
                                    id="btn-sidebar-custom"
                                    style={{ borderRadius: "50px" }}
                                >
                                    <BsIcons.BsFillFileEarmarkFill className="nav-icon mb-1" />{" "}
                                    <p>EP Monitoring Report</p>
                                </NavLink>
                            </li>
                            {accessLevel == 0 ? (
                                <li className="nav-item mt-2">
                                    <NavLink
                                        to="/Users"
                                        className="nav-link"
                                        id="btn-sidebar-custom"
                                        style={{ borderRadius: "50px" }}
                                    >
                                        <BsIcons.BsFillPeopleFill className="nav-icon mb-1" />{" "}
                                        <p>Users</p>
                                    </NavLink>
                                </li>
                            ) : (
                                <div></div>
                            )}
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    );
};

export default Sidebar;
