import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardHeader, CardBody, CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const UploadIssued = () => {
    const [files, setFiles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [uploadSummary, setUploadSummary] = useState(null);
    const [progress, setProgress] = useState(0);

    // State for offices
    const [regionalOffices, setRegionalOffices] = useState([]);
    const [districtOffices, setDistrictOffices] = useState([]);
    const [selectedRegionalOffice, setSelectedRegionalOffice] = useState("");
    const [selectedDistrictOffice, setSelectedDistrictOffice] = useState("");

    // Helper function to ensure we always have an array
    const ensureArray = (data) => {
        if (!data) return [];
        if (Array.isArray(data)) return data;
        return [data];
    };

    useEffect(() => {
        const fetchRegionalOffices = async () => {
            try {
                const response = await axios.get(
                    "https://api.nha.gov.ph/ep/trx_regional_district_office_fetch",
                    {
                        withCredentials: true,
                    }
                );
                console.log("Regional offices response:", response.data);
                setRegionalOffices(ensureArray(response.data));
            } catch (err) {
                console.error("Error fetching regional offices:", err);
                setRegionalOffices([]);
            }
        };
        fetchRegionalOffices();
    }, []);

    useEffect(() => {
        const fetchDistrictOffices = async () => {
            if (selectedRegionalOffice) {
                try {
                    const response = await axios.post(
                        "https://api.nha.gov.ph/ep/trx_district_office_fetch_by_regional_office",
                        {
                            regionalOfficeId: selectedRegionalOffice,
                        },
                        {
                            withCredentials: true,
                        }
                    );
                    console.log("District offices response:", response.data);
                    setDistrictOffices(ensureArray(response.data));
                } catch (err) {
                    console.error("Error fetching district offices:", err);
                    setDistrictOffices([]);
                }
            } else {
                setDistrictOffices([]);
            }
            setSelectedDistrictOffice("");
        };
        fetchDistrictOffices();
    }, [selectedRegionalOffice]);

    const handleFileChange = (e) => {
        setFiles(e.target.files);
        setError(null);
        setUploadSummary(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!files || files.length === 0) {
            setError("Please select files to upload");
            return;
        }
        if (!selectedRegionalOffice) {
            setError("Please select a Regional Office");
            return;
        }
        if (!selectedDistrictOffice) {
            setError("Please select a District Office");
            return;
        }

        setLoading(true);
        setError(null);
        setProgress(0);

        const formData = new FormData();

        // Debug logs
        console.log("Files before append:", files);
        console.log("Files length:", files.length);

        for (let i = 0; i < files.length; i++) {
            formData.append("pdfs", files[i]);
        }

        // Debug log - check FormData content
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        const regionalOfficeName = regionalOffices.find(
            (office) => office.id === selectedRegionalOffice
        )?.regionalOffice;
        const districtOfficeName = districtOffices.find(
            (office) => office.id === selectedDistrictOffice
        )?.districtOffice;

        if (!regionalOfficeName || !districtOfficeName) {
            setError("Unable to find office names. Please try again.");
            setLoading(false);
            return;
        }

        formData.append("regionalOffice", regionalOfficeName);
        formData.append("districtOffice", districtOfficeName);

        try {
            console.log("Sending request with FormData");
            const response = await axios.post(
                "https://api.nha.gov.ph/ep/trx_multiple_pdf_upload",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setProgress(percentCompleted);
                    },
                }
            );

            console.log("Upload response:", response.data);
            setUploadSummary(response.data.summary);
            setError(null);
        } catch (err) {
            console.error("Upload error:", err.response?.data);
            const errorMessage =
                err.response?.data?.error ||
                err.response?.data?.details ||
                err.message ||
                "An error occurred during upload";
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Sidebar />
            <div className="container mt-4">
                <Card elevation={2} sx={{ borderRadius: "15px" }}>
                    <CardHeader
                        title="Bulk PDF Upload"
                        sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                    />
                    <CardContent>
                        {error && (
                            <Alert severity="error" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}

                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Regional Office</InputLabel>
                                        <Select
                                            value={selectedRegionalOffice}
                                            onChange={(e) =>
                                                setSelectedRegionalOffice(
                                                    e.target.value
                                                )
                                            }
                                            disabled={loading}
                                            required
                                        >
                                            <MenuItem value="">
                                                Select Regional Office
                                            </MenuItem>
                                            {regionalOffices.map((office) => (
                                                <MenuItem
                                                    value={office.id}
                                                    key={office.id}
                                                >
                                                    {office.regionalOffice}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>District Office</InputLabel>
                                        <Select
                                            value={selectedDistrictOffice}
                                            onChange={(e) =>
                                                setSelectedDistrictOffice(
                                                    e.target.value
                                                )
                                            }
                                            disabled={
                                                !selectedRegionalOffice ||
                                                loading
                                            }
                                            required
                                        >
                                            <MenuItem value="">
                                                Select District Office
                                            </MenuItem>
                                            {districtOffices.map((office) => (
                                                <MenuItem
                                                    key={office.id}
                                                    value={office.id}
                                                >
                                                    {office.districtOffice}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <input
                                type="file"
                                multiple
                                accept=".pdf"
                                onChange={handleFileChange}
                                disabled={loading}
                                required
                                style={{
                                    marginTop: "24px",
                                    marginBottom: "16px",
                                }}
                            />

                            {loading && (
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{ my: 2 }}
                                />
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={
                                    loading ||
                                    !files ||
                                    !selectedRegionalOffice ||
                                    !selectedDistrictOffice
                                }
                                sx={{ mt: 2 }}
                            >
                                {loading ? "Uploading..." : "Upload Files"}
                            </Button>
                        </form>

                        {uploadSummary && (
                            <div style={{ marginTop: "24px" }}>
                                <h4>Upload Summary</h4>
                                <ul style={{ listStyle: "none", padding: 0 }}>
                                    <li>
                                        Total Files: {uploadSummary.totalFiles}
                                    </li>
                                    <li>
                                        Successful Uploads:{" "}
                                        {uploadSummary.successfulUploads}
                                    </li>
                                    <li>
                                        Failed Uploads:{" "}
                                        {uploadSummary.failedUploads}
                                    </li>
                                    <li>
                                        Processed Batches:{" "}
                                        {uploadSummary.processedBatches} of{" "}
                                        {uploadSummary.totalBatches}
                                    </li>
                                    <li>
                                        Upload Path: {uploadSummary.uploadPath}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default UploadIssued;
