import React, { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Tooltip from "@mui/material/Tooltip";
import handleLogout from "../pages/Logout"; // Ensure the correct import path
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Cookies from "js-cookie";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    // Fallback to default values if the name is empty or too short
    const initials = name
        .split(" ")
        .map((word) => word[0] || "") // Get the first character of each word or an empty string if the word is empty
        .join(""); // Join the initials together

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: initials || "NA", // Use "NA" if initials are empty
    };
}

const Header = () => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [accessLevel, setAccessLevel] = useState("");
    const [name, setName] = useState("");

    useEffect(() => {
        const retrievedAccessLevel = Cookies.get("accessLevel");
        const firstName = Cookies.get("firstName") || "";
        const lastName = Cookies.get("lastName") || "";
        const fullName = `${firstName} ${lastName}`;

        setAccessLevel(retrievedAccessLevel);
        setName(fullName);
    }, []);
    return (
        <div>
            <nav
                className="main-header navbar navbar-expand bg-white navbar-light"
                style={{ zIndex: 1 }}
            >
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <button
                            className="nav-link"
                            data-widget="pushmenu"
                            href="#"
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        >
                            <BsIcons.BsList className="text-lg" />
                        </button>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item ml-2">
                        <div
                            className="user-block mr-1 pr-2 row"
                            style={{ borderRight: "solid 2px #c4c5c4" }}
                        >
                            <Avatar {...stringAvatar(name)} />
                            <span
                                className="ml-2 text-left user-block mt-1"
                                style={{ lineHeight: "17px" }}
                            >
                                <span className="font-weight-bold">
                                    <a
                                        href={"/#/changepassword"}
                                        style={{ color: "#000" }}
                                    >
                                        {name}
                                    </a>
                                </span>
                                <br />
                                <small style={{ color: "#24dc8f" }}>
                                    <b>
                                        {accessLevel == 2
                                            ? "District Office"
                                            : accessLevel < 2 &&
                                              accessLevel >= 1
                                            ? "Regional Office"
                                            : "RDSD"}
                                    </b>
                                </small>
                            </span>
                        </div>
                    </li>
                    <li className="nav-item mr-3">
                        <Tooltip title="Logout">
                            <IconButton
                                aria-label="logout"
                                onClick={() => handleLogout(navigate)}
                            >
                                <PowerSettingsNewIcon
                                    style={{ color: "#24dc8f" }}
                                />
                            </IconButton>
                        </Tooltip>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Header;
