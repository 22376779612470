import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import * as BsIcons from "react-icons/bs";
// charts
import { Sex } from "../charts/Sex";
import { StatusAward } from "../charts/StatusAward";
import EPStatus_Chart from "../charts/EPStatus";
// mui
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { width } from "@mui/system";

import axios from "axios";

const Dashboard = () => {
    // mui
    const selectLabelStyle = {
        fontWeight: "normal",
    };
    const labelStyle = {
        fontWeight: "normal",
    };
    const [selectedStatus, setSelectedStatus] = useState("");
    const [cardCounts, setCardCounts] = useState("");
    const [loading, setLoading] = useState(true);
    // mui select
    const [AccessLevel, setAccessLevel] = useState("");
    const [RO, setRegion] = useState("");
    const [Status, setStatus] = useState("");

    const handleChangeAccessLevel = (event) => {
        setAccessLevel(event.target.value);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    };
    const [selectedRegionalOffice, setSelectedRegionalOffice] = useState(""); // State to hold the selected regional office

    const [regionalOffices, setRegionalOffices] = useState([]);

    const [startDate, setStartDate] = useState(dayjs("2000-01-01"));
    const [endDate, setEndDate] = useState(dayjs());

    useEffect(() => {
        const fetchRegionalDistrictOffices = async () => {
            try {
                const response = await axios.get(
                    "https://api.nha.gov.ph/ep/trx_regional_dashboard",
                    { withCredentials: true }
                );
                const responseData = response.data;
                const regionalOffices = responseData.map(
                    (data) => data.regionalOffice
                );

                // Set regional offices state
                setRegionalOffices(regionalOffices);
            } catch (error) {
                console.error(
                    "Error fetching regional district offices:",
                    error
                );
                // Handle error, show message to user, etc.
            }
        };

        // Call the fetch function only once when the component is mounted
        fetchRegionalDistrictOffices();
    }, []);

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await axios.get(
                    "https://api.nha.gov.ph/ep/trx_count_all_ep_status",
                    { withCredentials: true },
                    {
                        params: { selectedRegionalOffice },
                    }
                );
                if (response && response.data && response.data.beneficiaries) {
                    const beneficiaries = response.data.beneficiaries;

                    // Filter beneficiaries based on startDate and endDate
                    const filteredBeneficiaries = beneficiaries.filter(
                        (beneficiary) => {
                            const printedDate = new Date(
                                beneficiary.epDatePrinted
                            );
                            return (
                                printedDate >= startDate &&
                                printedDate <= endDate
                            );
                        }
                    );
                    // Initialize count variables
                    let issuedCount = 0;
                    let deferredCount = 0;
                    let cancelledCount = 0;
                    let spoiledCount = 0;
                    let printedCount = 0;

                    // Iterate over filtered beneficiaries and count statuses
                    filteredBeneficiaries.forEach((beneficiary) => {
                        if (beneficiary.epPrintStatus === "Issued") {
                            issuedCount++;
                        } else if (beneficiary.epPrintStatus === "Deferred") {
                            deferredCount++;
                        } else if (beneficiary.epPrintStatus === "Cancelled") {
                            cancelledCount++;
                        } else if (beneficiary.epPrintStatus === "Spoiled") {
                            spoiledCount++;
                        }

                        if (beneficiary.status === "With Printed EP") {
                            printedCount++;
                        }
                    });

                    // Update component state with counts
                    setCardCounts({
                        issued: issuedCount,
                        deferred: deferredCount,
                        cancelled: cancelledCount,
                        spoiled: spoiledCount,
                        printed: printedCount,
                    });
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching counts:", error);
                setLoading(false);
            }
        };

        fetchCounts();
    }, [startDate, endDate, selectedRegionalOffice]);

    return (
        <div>
            <Header />
            <Sidebar />
            <div className="content-wrapper pl-2 pr-2">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Entry Pass Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pl-3 pr-3">
                    <div className="row">
                        <div className="col-sm-3">
                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body"
                                    style={{ display: "flex" }}
                                >
                                    <div className="w-25">
                                        <div
                                            className="card h-100 w-100 elevation-2"
                                            style={{
                                                backgroundColor: "#2be0a9",
                                                borderRadius: "15px",
                                            }}
                                        >
                                            <BsIcons.BsPrinterFill
                                                className="text-xl"
                                                style={{
                                                    margin: "auto",
                                                    color: "#32312c",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-75 pl-2">
                                        <h5 className="mb-0">
                                            Total EP Printed
                                        </h5>
                                        <h3>{cardCounts.printed}</h3>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body"
                                    style={{ display: "flex" }}
                                >
                                    <div className="w-25">
                                        <div
                                            className="card h-100 w-100 elevation-2"
                                            style={{
                                                backgroundColor: "#34a8ff",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <BsIcons.BsFillPatchCheckFill
                                                className="text-xl"
                                                style={{
                                                    margin: "auto",
                                                    color: "#32312c",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-75 pl-2">
                                        <h5 className="mb-0">
                                            Total EP Issued
                                        </h5>
                                        <h2>{cardCounts.issued}</h2>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body"
                                    style={{ display: "flex" }}
                                >
                                    <div className="w-25">
                                        <div
                                            className="card h-100 w-100 elevation-2"
                                            style={{
                                                backgroundColor: "#26daf4",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <BsIcons.BsClockFill
                                                className="text-xl"
                                                style={{
                                                    margin: "auto",
                                                    color: "#32312c",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-75 pl-2">
                                        <h5 className="mb-0">
                                            Total EP Spoiled
                                        </h5>
                                        <h3>{cardCounts.spoiled}</h3>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body"
                                    style={{ display: "flex" }}
                                >
                                    <div className="w-25">
                                        <div
                                            className="card h-100 w-100 elevation-2"
                                            style={{
                                                backgroundColor: "#e23825",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <BsIcons.BsPersonFillX
                                                className="text-xl"
                                                style={{
                                                    margin: "auto",
                                                    color: "#32312c",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-75 pl-2">
                                        <h5 className="mb-0">
                                            Total EP Cancelled
                                        </h5>
                                        <h3>{cardCounts.cancelled}</h3>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body"
                                    style={{ display: "flex" }}
                                >
                                    <div className="w-25">
                                        <div
                                            className="card h-100 w-100 elevation-2"
                                            style={{
                                                backgroundColor: "#f9e165",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <BsIcons.BsExclamationTriangleFill
                                                className="text-xl"
                                                style={{
                                                    margin: "auto",
                                                    color: "#32312c",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-75 pl-2">
                                        <h5 className="mb-0">
                                            Total EP Deferred
                                        </h5>
                                        <h3>{cardCounts.deferred}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-9">
                            <div
                                className="card elevation-2"
                                style={{ borderRadius: "15px" }}
                            >
                                <div
                                    className="card-body row"
                                    style={{ display: "flex" }}
                                >
                                    <div className="col-sm-3">
                                        <label className="mb-0">
                                            Start Date
                                        </label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                slotProps={{
                                                    textField: {
                                                        marginTop: "0px",
                                                    },
                                                }}
                                            >
                                                <DesktopDatePicker
                                                    className="w-100"
                                                    value={startDate} // Use the state variable
                                                    onChange={(newValue) =>
                                                        setStartDate(newValue)
                                                    } // Handle the change
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            marginTop: "0px",
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="col-sm-3">
                                        <label className="mb-0">End Date</label>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DemoContainer
                                                components={["DatePicker"]}
                                                slotProps={{
                                                    textField: {
                                                        marginTop: "0px",
                                                    },
                                                }}
                                            >
                                                <DesktopDatePicker
                                                    className="w-100"
                                                    value={endDate} // Use the state variable
                                                    onChange={(newValue) =>
                                                        setEndDate(newValue)
                                                    } // Handle the change
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                            marginTop: "0px",
                                                        },
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>

                                    <div className="col-sm-3">
                                        <label>Regional Office</label>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                labelId=""
                                                id=""
                                                displayEmpty
                                                value={selectedRegionalOffice}
                                                onChange={(e) =>
                                                    setSelectedRegionalOffice(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem
                                                    value=""
                                                    placeholder="Select Regional Office"
                                                >
                                                    All Regional Offices
                                                </MenuItem>
                                                {regionalOffices.map(
                                                    (office) => (
                                                        <MenuItem
                                                            value={office}
                                                            key={office}
                                                        >
                                                            {office}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    <div className="col-sm-3">
                                        <label>Status</label>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                labelId=""
                                                id=""
                                                displayEmpty
                                                value={selectedStatus}
                                                onChange={(e) =>
                                                    setSelectedStatus(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value="">
                                                    All EP Status
                                                </MenuItem>
                                                <MenuItem value="Issued">
                                                    Issued
                                                </MenuItem>
                                                <MenuItem value="Deferred">
                                                    Deferred
                                                </MenuItem>
                                                <MenuItem value="Cancelled">
                                                    Cancelled
                                                </MenuItem>
                                                <MenuItem value="Spoiled">
                                                    Spoiled
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="card elevation-2 mt-4"
                                style={{
                                    borderTop: "4px solid #292726",
                                    borderRadius: "15px",
                                }}
                            >
                                <div className="card-header">
                                    <div className="card-title font-weight-bold">
                                        Entry Pass Status Per Regional Office
                                    </div>
                                </div>
                                <div className="card-body">
                                    <EPStatus_Chart
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectedRegionalOffice={
                                            selectedRegionalOffice
                                        }
                                        selectedStatus={selectedStatus}
                                    />
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-sm-6">
                                    <div
                                        className="card elevation-2"
                                        style={{
                                            borderTop: "4px solid #292726",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        <div className="card-header">
                                            <div className="card-title font-weight-bold">
                                                Sex
                                            </div>
                                        </div>
                                        <div
                                            className="card-body"
                                            style={{ margin: "auto" }}
                                        >
                                            <Sex
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectedRegionalOffice={
                                                    selectedRegionalOffice
                                                }
                                                selectedStatus={selectedStatus}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div
                                        className="card elevation-2"
                                        style={{
                                            borderTop: "4px solid #292726",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        <div className="card-header">
                                            <div className="card-title font-weight-bold">
                                                Status of Award
                                            </div>
                                        </div>
                                        <div
                                            className="card-body"
                                            style={{ margin: "auto" }}
                                        >
                                            <StatusAward
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectedRegionalOffice={
                                                    selectedRegionalOffice
                                                }
                                                selectedStatus={selectedStatus}
                                                style={{ width: "150px" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Dashboard;
