import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { Select, MenuItem, TextField, InputLabel, FormControl, Button, Grid } from "@mui/material";

const SummaryReport = () => {
    const [requests, setRequests] = useState(null);
    const [filterField, setFilterField] = useState("requestNumber"); // Field to filter by
    const [filterValue, setFilterValue] = useState(""); // Value to filter
    const [filteredRequests, setFilteredRequests] = useState([]);
  
    useEffect(() => {
      const fetchRequests = async () => {
        try {
          const response = await axios.get(
            `https://api.nha.gov.ph/ep/fetch_summary_requests`,
            {
              withCredentials: true, // Include cookies with the request
            }
          );
          setRequests(response.data.requests);
          setFilteredRequests(response.data.requests); // Initialize filtered requests
          console.log("responseddd", response.data);
        } catch (error) {
          console.error("Error fetching requests:", error);
        }
      };
  
      fetchRequests();
    }, []);
  
    // Function to handle filtering dynamically
    useEffect(() => {
      if (requests) {
        const filtered = requests.filter((request) =>
          (request[filterField] || "")
            .toString()
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
        setFilteredRequests(filtered);
      }
    }, [filterField, filterValue, requests]);
  
    const downloadExcel = () => {
        if (!filteredRequests || filteredRequests.length === 0) {
          alert("No data to download!");
          return;
        }
      
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Summary Report");
      
        // Title styles
        const titleStyleLeft = {
          font: { size: 12 },
          alignment: { horizontal: "left", vertical: "middle" },
        };
      
        const titleStyleCenter = {
          font: { size: 16, bold: true },
          alignment: { horizontal: "left", vertical: "middle" },
        };
      
        // Add title rows
        worksheet.getCell("A1").value = "National Housing Authority";
        worksheet.getCell("A1").style = titleStyleLeft;
      
        worksheet.getCell("A2").value = "Resettlement & Development Services Department";
        worksheet.getCell("A2").style = titleStyleLeft;
      
        worksheet.getCell("A3").value = "Relocation & Resettlement Monitoring Division";
        worksheet.getCell("A3").style = titleStyleLeft;
    

        worksheet.getCell("A4").value = "SUMMARY REPORT ON ENTRY PASS REQUISITION";
        worksheet.getCell("A4").style = titleStyleCenter;
      
        // Adjust row heights for title
        worksheet.getRow(1).height = 20;
        worksheet.getRow(2).height = 20;
        worksheet.getRow(3).height = 20;
        worksheet.getRow(4).height = 25;
      
        // Define header styles
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          },
          fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "F4F4F4" },
          },
        };
      
        // Define header rows
        worksheet.mergeCells("A6:A8"); // NO.
        worksheet.mergeCells("B6:H6"); // REQUISITION
        worksheet.mergeCells("I6:P6"); // DATE & TIME OF RECEIPT
        worksheet.mergeCells("Q6:U6"); // DATE & TIME OF RECEIPT
        worksheet.mergeCells("V6:V8"); // REMARKS
      
        worksheet.mergeCells("B7:B8"); // DISTRICT OFFICE
        worksheet.mergeCells("C7:C8"); // REGIONAL OFFICE
        worksheet.mergeCells("D7:D8"); // REQUEST NO.
        worksheet.mergeCells("E7:E8"); // CONTROL NO.
        worksheet.mergeCells("F7:F8"); // NAME OF REGIONAL EVALUATOR
        worksheet.mergeCells("G7:G8"); // NAME OF RDSD EVALUATOR
        worksheet.mergeCells("H7:H8"); // PLACE OF ORIGIN/PROJECT NAME
      
        worksheet.mergeCells("I7:J7"); // REGIONAL OFFICE
        worksheet.mergeCells("K7:P7"); // RDSD
      
        worksheet.mergeCells("Q7:Q8"); // NO. OF EP REQUEST
        worksheet.mergeCells("R7:R8"); // ISSUED
        worksheet.mergeCells("S7:S8"); // DEFERRED
        worksheet.mergeCells("T7:T8"); // CANCELLED
        worksheet.mergeCells("U7:U8"); // SPOILED
      
        worksheet.getRow(6).height = 30;
        worksheet.getRow(7).height = 20;
        worksheet.getRow(8).height = 20;
      
        // Add header titles
        worksheet.getCell("A6").value = "NO.";
        worksheet.getCell("B6").value = "REQUISITION";
        worksheet.getCell("I6").value = "DATE & TIME OF RECEIPT";
        worksheet.getCell("Q6").value = "LIQUIDATION STATUS";
        worksheet.getCell("V6").value = "REMARKS";
      
        worksheet.getCell("B7").value = "DISTRICT OFFICE";
        worksheet.getCell("C7").value = "REGIONAL OFFICE";
        worksheet.getCell("D7").value = "REQUEST NO.";
        worksheet.getCell("E7").value = "CONTROL NO.";
        worksheet.getCell("F7").value = "NAME OF REGIONAL EVALUATOR";
        worksheet.getCell("G7").value = "NAME OF RDSD EVALUATOR";
        worksheet.getCell("H7").value = "PLACE OF ORIGIN/PROJECT NAME";
      
        worksheet.getCell("I7").value = "REGIONAL OFFICE";
        worksheet.getCell("K7").value = "RDSD";
      
        worksheet.getCell("I8").value = "IN";
        worksheet.getCell("J8").value = "OUT";
        worksheet.getCell("K8").value = "IN";
        worksheet.getCell("L8").value = "OUT";

           worksheet.getCell("M8").value = "IN";
        worksheet.getCell("N8").value = "OUT";
            worksheet.getCell("O8").value = "IN";
        worksheet.getCell("P8").value = "OUT";
        
      
        worksheet.getCell("Q7").value = "NO. OF EP REQUEST";
        worksheet.getCell("R7").value = "ISSUED";
        worksheet.getCell("S7").value = "DEFERRED";
        worksheet.getCell("T7").value = "CANCELLED";
        worksheet.getCell("U7").value = "SPOILED";
      
        // Apply styles to headers
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          if (rowNumber >= 6 && rowNumber <= 8) {
            row.eachCell((cell) => {
              cell.style = headerStyle;
            });
          }
        });
      
        // Add data rows
        filteredRequests.forEach((request, index) => {
          const controlNumbers = request.controlNumbers;
          const lowest = Math.min(...controlNumbers);
          const highest = Math.max(...controlNumbers);
          const epReq = request.EntryPassRequisition;
      
          const row = worksheet.addRow([
            index + 1,
            request.districtOffice || "-",
            request.regionalOffice || "-",
            request.requestNumber || "-",
            `${lowest} - ${highest}`,
            request.districtEvaluator || "-",
            request.regionalEvaluator || "-",
            request.projectName || "-",
            epReq ? epReq.regionalIn : "-",
            epReq ? epReq.regionalOut : "-",
            epReq ? epReq.rdsdIn : "-",
            epReq ? epReq.rdsdOut : "-",
            epReq ? epReq.rdsdRAIn : "-",
            epReq ? epReq.rdsdRAOut : "-",
            epReq ? epReq.rdsdDMIn : "-",
            epReq ? epReq.rdsdDMOut : "-",
            epReq ? epReq.numberOfEPRequest : "-",
            epReq ? epReq.numberOfIssued : "-",
            epReq ? epReq.numberOfDeferred : "-",
            epReq ? epReq.numberOfCancelled : "-",
            epReq ? epReq.numberOfSpoiled : "-",
            "", // Remarks
          ]);
      
          // Apply borders to data rows
          row.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });
      
        // Auto-size columns
        worksheet.columns.forEach((column) => {
          column.width = Math.max(
            ...column.values.map((val) => (val ? val.toString().length : 10))
          );
        });
      
        // Save workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer]), "FilteredSummaryReport.xlsx");
        });
      };
  
    return (
        <div>
            <Header />
            <Sidebar />

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                            <h1>Summary Report</h1>
      <Grid container spacing={2}>
        {/* Filter controls */}
        <Grid item xs={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="filterField">Filter By</InputLabel>
            <Select
              id="filterField"
              value={filterField}
              onChange={(e) => setFilterField(e.target.value)}
              label="Filter By"
            >
              <MenuItem value="requestNumber">Request Number</MenuItem>
              <MenuItem value="districtOffice">District Office</MenuItem>
              <MenuItem value="regionalOffice">Regional Office</MenuItem>
              <MenuItem value="projectName">Project Name</MenuItem>
              <MenuItem value="districtEvaluator">District Evaluator</MenuItem>
              <MenuItem value="regionalEvaluator">Regional Evaluator</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={9}>
          <TextField
            fullWidth
            label={`Filter by ${filterField}`}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={downloadExcel}
        sx={{ mt: 2 }}
      >
        Download Excel
      </Button>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div
                            className="card elevation-2"
                            style={{
                                borderTop: "4px solid #292726",
                                borderRadius: "15px",
                            }}
                        >
                            <div className="card-header pb-1">
                                <p className="mb-0">
                                    National Housing Authority
                                </p>
                                <p className="mb-0">
                                    Resettlement & Development Services
                                    Department
                                </p>
                                <p className="mb-0">
                                    Relocation & Resettlement Monitoring
                                    Division
                                </p>
                                <h5 className="font-weight-bold pt-2">
                                    SUMMARY REPORT ON ENTRY PASS REQUISITION
                                </h5>
                            </div>

                            <div
                                className="card-body table-responsive p-0"
                                style={{ height: 600 }}
                            >
                                <table className="table table-head-fixed bg-white table-hover table-bordered text-wrap">
                                    <thead
                                        style={{
                                            borderBottom: "2px solid #ccc",
                                        }}
                                    >
                                        <tr
                                            className="text-sm"
                                            style={{
                                                position: "sticky",
                                                top: 0, // Adjust the value if needed
                                                zIndex: 1,
                                            }}
                                        >
                                            <th
                                                rowSpan="3"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NO.
                                            </th>
                                            <th
                                                colSpan="7"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REQUISITION
                                            </th>
                                            <th
                                                colSpan="8"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DATE & TIME OF RECEIPT
                                            </th>
                                            <th
                                                colSpan="5"
                                                className="text-center"
                                                style={{
                                                    verticalAlign: "middle",
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                LIQUIDATION STATUS
                                            </th>
                                            <th
                                                rowSpan="3"
                                                className="text-middle text-center"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REMARKS
                                            </th>
                                        </tr>

                                        <tr
                                            className="text-center text-sm"
                                            style={{
                                                position: "sticky",
                                                top: "36px", // Height of the first row, adjust accordingly
                                                zIndex: 1,
                                            }}
                                        >
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DISTRICT OFFICE
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REGIONAL OFFICE
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REQUEST NO.
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                CONTROL NO.
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NAME OF REGIONAL EVALUATOR
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NAME OF RDSD EVALUATOR
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                PLACE OF ORIGIN/PROJECT NAME
                                            </th>
                                            <th
                                                colSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                REGIONAL OFFICE
                                            </th>
                                            <th
                                                colSpan="6"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                RDSD
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                NO. OF EP REQUEST
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                ISSUED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                DEFERRED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                CANCELLED
                                            </th>
                                            <th
                                                rowSpan="5"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                SPOILED
                                            </th>
                                        </tr>

                                        <tr
                                            className="text-center text-sm"
                                            style={{
                                                position: "sticky",
                                                top: "72px", // Height of the first and second rows combined, adjust accordingly
                                                zIndex: 2,
                                            }}
                                        >
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                IN
                                            </th>
                                            <th
                                                rowSpan="2"
                                                style={{
                                                    border: "1px solid #ccc",
                                                    backgroundColor: "#f4f4f4",
                                                }}
                                            >
                                                OUT
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center" style={{overflow: "auto", maxHeight:"80vh"}}>
                                        {filteredRequests
                                            ? filteredRequests.map((request, index) => {
                                                const controlNumbers = request.controlNumbers;
                                                const lowest = Math.min(...controlNumbers);
                                                const highest = Math.max(...controlNumbers);
                                                  const epReq =
                                                      request.EntryPassRequisition; // Access EntryPassRequisition for each request
                                                  return (
                                                      <tr key={index + 1}>
                                                          <td className="text-middle">
                                                              {index + 1}
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.districtOffice
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.regionalOffice
                                                              }
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.requestNumber
                                                              }
                                                          </td>
                                                          <td>
                                                            {lowest} <br /> - <br />
                                                            {highest}
                  </td>
                                                          <td>
                                                              {request
                                                                  ? request.districtEvaluator
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {request
                                                                  ? request.regionalEvaluator
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {
                                                                  request.projectName
                                                              }
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.regionalIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.regionalOut
                                                                  : "="}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdOut
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdRAIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdRAOut
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdDMIn
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.rdsdDMOut
                                                                  : "-"}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfEPRequest
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfIssued
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfDeferred
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfCancelled
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td>
                                                              {epReq
                                                                  ? epReq.numberOfSpoiled
                                                                  : "-"}{" "}
                                                          </td>
                                                          <td></td>
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryReport;
