import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// Check if the current page is the login page
const isLoginPage = () => {
  const currentPath = window.location.pathname || window.location.hash;
  return currentPath === '/' || currentPath === '/#'; // Adjust if using HashRouter
};

if (!isLoginPage()) {
  // Add request interceptor
  axios.interceptors.request.use(
    (config) => {
      // Always include credentials
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Clear all auth cookies
        const cookiesToRemove = [
          'email',
          'firstName',
          'accessLevel',
          'districtOffice',
          'regionalOffice',
          'userId',
          'lastName',
        ];
        cookiesToRemove.forEach((cookie) => Cookies.remove(cookie));

        // Show error message
        toast.error('Session expired. Please login again.');

        // Use window.location.hash for HashRouter
        window.location.hash = '/';

        return Promise.reject(error);
      }

      // Handle other errors
      const errorMessage = error.response?.data?.message || 'An error occurred';
      if (error.response?.status !== 404) {
        // Don't show toast for 404s
        toast.error(errorMessage);
      }
      return Promise.reject(error);
    }
  );
}

export default axios;