import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import IconButton from "@mui/material/IconButton";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Textarea from "@mui/joy/Textarea";
import Button from "@mui/material/Button";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { InputLabel } from "@mui/material";
import { BsDownload } from "react-icons/bs";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
dayjs.extend(utc);
dayjs.extend(timezone);
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjust the width as needed
    maxWidth: "800px", // Set a maximum width to prevent overflow
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const labelStyle = {
    fontWeight: "normal",
};

const EPstatus = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [status, setStatus] = useState("");
    const [statusOfAward, setStatusOfAward] = useState("");
    const [epRemarks, setEpRemarks] = useState("");
    const [rdsdRemarks, setRdsdRemarks] = useState("");
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [accessLevel, setAccessLevel] = useState();
    const [awardStatus, setAwardStatus] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [pointOfOriginOptions, setPointOfOriginOptions] = useState([]);
    const [resettlementSiteOptions, setResettlementSiteOptions] = useState([]);
    const [filterField, setFilterField] = useState("");
    const [formData, setFormData] = useState({
        beneficiary: "",
        lastName: "",
        firstName: "",
        middleName: "",
        sex: "",
        spouseLastName: "",
        spouseFirstName: "",
        spouseMiddleName: "",
        spouseSex: "",
        pointOfOrigin: {},
        resettlement: {},
    });
    useEffect(() => {
        const al = Cookies.get("accessLevel");
        setAccessLevel(al);
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const fetchPrintedStatusBeneficiaries = async () => {
        try {
            const response = await axios.get(
                "https://api.nha.gov.ph/ep/trx_ep_printed_status",
                { withCredentials: true }
            );

            if (response.status === 200) {
                setData(response.data.data); // Set the raw data
            } else {
                console.error(
                    "Failed to fetch beneficiaries:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error fetching beneficiaries:", error);
        }
    };

    // Function to filter the data by controlNumber containing searchTerm
    // const filterBeneficiaries = (data, searchTerm) => {
    //     return data.filter(
    //         (item) =>
    //             item.controlNumber &&
    //             String(item.controlNumber).includes(searchTerm)
    //     );
    // };
    const filterBeneficiaries = (data, searchTerm, filterField) => {
        let filteredData = data;

        // Apply filter conditionally based on selected field
        if (filterField && searchTerm) {
            filteredData = filteredData.filter((item) =>
                String(item[filterField])
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            );
        }

        return filteredData;
    };

    
    useEffect(() => {
        if (searchTerm && filterField && data.length > 0) {
            // Apply filtering if there is a search term and filter field selected
            const filtered = filterBeneficiaries(data, searchTerm, filterField);
            setFilteredData(filtered); // Set the filtered data
        } else {
            setFilteredData(data); // If no searchTerm, show all data
        }
    }, [searchTerm, filterField, data]); // Runs when searchTerm, filterField, or data changes

    // useEffect to fetch data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            await fetchPrintedStatusBeneficiaries();
        };

        fetchData();
    }, []); // Only run on mount

    // useEffect to filter data when searchTerm or data changes

    const fetchEditBeneficiary = async (id) => {
        try {
            const response = await axios.get(
                `https://api.nha.gov.ph/ep/trx_one_beneficiary/${id}`,
                { withCredentials: true }
            );

            if (response.status === 200) {
                setModalData(response.data);
                if (response.status === 200) {
                    const beneficiaryData = response.data;
                    console.log("respos", response.data);
                    // Update formData state with the fetched data
                    setPointOfOriginOptions([beneficiaryData.PointOfOrigin]);
                    setResettlementSiteOptions([beneficiaryData.Resettlement]);
                    setFormData({
                        beneficiary: beneficiaryData,
                        lastName: beneficiaryData.lastName || "",
                        firstName: beneficiaryData.firstName || "",
                        middleName: beneficiaryData.middleName || "",
                        sex: beneficiaryData.sex || "",
                        spouseLastName:
                            beneficiaryData.Spouse.spouseLastName || "",
                        spouseFirstName:
                            beneficiaryData.Spouse.spouseFirstName || "",
                        spouseMiddleName:
                            beneficiaryData.Spouse.spouseMiddleName || "",
                        spouseSex: beneficiaryData.Spouse.spouseSex || "",
                        pointOfOrigin: beneficiaryData.PointOfOrigin || {},
                        resettlement: beneficiaryData.Resettlement || {},
                    });

                    setModalData(beneficiaryData); // You can still keep this to set modal data if needed
                    setIsModalOpen(true); // Open modal after setting data
                    console.log(formData);
                } else {
                    console.error(
                        "Failed to fetch beneficiary:",
                        response.data.message
                    );
                }
                setIsModalOpen(true); // Open modal after fetching data
            } else {
                console.error(
                    "Failed to fetch beneficiary:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error fetching beneficiary:", error);
        }
    };
    const fetchOneBeneficiary = async (id) => {
        try {
            const response = await axios.get(
                `https://api.nha.gov.ph/ep/trx_one_beneficiary/${id}`,
                { withCredentials: true }
            );

            if (response.status === 200) {
                setModalData(response.data);
                setSelectedDate(dayjs(response.data.epReleaseDate));
                setStatus(response.data.epPrintStatus);
                setStatusOfAward(response.data.statusOfAward);
                setEpRemarks(response.data.epRemarks);
                setRdsdRemarks(response.data.rdsdRemarks);
                setOpenEdit(true); // Open modal after fetching data
            } else {
                console.error(
                    "Failed to fetch beneficiary:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error fetching beneficiary:", error);
        }
    };
    const updateBeneficiaryDetails = async (
        id,
        epReleaseDate,
        epPrintStatus,
        statusOfAward,
        epRemarks,
        rdsdRemarks
    ) => {
        try {
            // Create request body, excluding epReleaseDate if it hasn't changed
            const requestBody = {
                epPrintStatus,
                statusOfAward,
                epRemarks,
                rdsdRemarks,
            };

            // Only add epReleaseDate to request if it exists and has changed
            if (epReleaseDate !== undefined || epReleaseDate == null) {
                requestBody.epReleaseDate = dayjs(epReleaseDate)
                    .tz("Asia/Shanghai")
                    .format("YYYY-MM-DD");
            }

            const response = await axios.put(
                `https://api.nha.gov.ph/ep/trx_update_beneficiaryDetails/${id}`,
                requestBody
            );

            if (response.data.success) {
                toast.success("Beneficiary details updated successfully");
                fetchPrintedStatusBeneficiaries(); // Refresh the list after update
                setOpenEdit(false); // Close the modal after successful update
            } else {
                console.error(
                    "Failed to update beneficiary details:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error updating beneficiary details:", error);
        }
    };

    const updateBeneficiaryEp = async (formData) => {
        const id = formData.beneficiary.id;
        try {
            const response = await axios.put(
                `https://api.nha.gov.ph/ep/trx_update_region/${id}`,
                {
                    formData,
                }
            );

            if (response.data.success) {
                toast.success("Beneficiary details updated successfully");
                fetchPrintedStatusBeneficiaries(); // Refresh the list after update
                setIsModalOpen(false); // Close the modal after successful update
            } else {
                console.error(
                    "Failed to update beneficiary details:",
                    response.data.message
                );
            }
        } catch (error) {
            console.error("Error updating beneficiary details:", error);
        }
    };
    useEffect(() => {
        fetchPrintedStatusBeneficiaries();
    }, []);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleStatusOfAwardChange = (event) => {
        setStatusOfAward(event.target.value);
    };

    const handleEpRemarksChange = (event) => {
        setEpRemarks(event.target.value);
    };

    const handleRdsdRemarksChange = (event) => {
        setRdsdRemarks(event.target.value);
    };

    const handleSubmit = () => {
        updateBeneficiaryDetails(
            modalData.id,
            selectedDate,
            status,
            statusOfAward,
            epRemarks,
            rdsdRemarks
        );
    };
    const handleSubmit2 = () => {
        updateBeneficiaryEp(formData);
    };

    const downloadExcel = () => {
        // Create a workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Beneficiaries");
      
        // Define columns for the worksheet
        worksheet.columns = [
          { header: "No", key: "no", width: 5 },
          { header: "Control Number", key: "controlNumber", width: 20 },
          { header: "Last Name", key: "lastName", width: 15 },
          { header: "First Name", key: "firstName", width: 15 },
          { header: "Middle Name", key: "middleName", width: 15 },
          { header: "Date Relocated", key: "relocationDate", width: 15 },
          { header: "Status", key: "epPrintStatus", width: 20 },
          { header: "Status Of Award", key: "statusOfAward", width: 15 },
          { header: "EP Remarks", key: "epRemarks", width: 20 },
          { header: "RDSD Remarks", key: "rdsdRemarks", width: 20 },
        ];
      
        // Map `filteredData` to the worksheet rows
        filteredData.forEach((beneficiary, index) => {
          worksheet.addRow({
            no: index + 1,
            controlNumber: beneficiary.controlNumber || "",
            lastName: beneficiary.lastName || "",
            firstName: beneficiary.firstName || "",
            middleName: beneficiary.middleName || "",
            relocationDate: beneficiary.relocationDate || "",
            epPrintStatus: beneficiary.epPrintStatus || "",
            statusOfAward: beneficiary.statusOfAward || "",
            epRemarks: beneficiary.epRemarks || "",
            rdsdRemarks: beneficiary.rdsdRemarks || "",
          });
        });
      
        // Apply styles (optional)
        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true }; // Make the header row bold
        });
      
        // Generate and download the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          saveAs(blob, "Beneficiaries.xlsx");
        });
      };
      
    return (
        <div>
            <Header />
            <Sidebar />
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: "800px",
                        backgroundColor: "#fff", // Or a suitable color for `bgcolor: "background.paper"`
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: "16px",
                        overflow: "hidden",
                    }}
                    className="modal-viewInfo card userModal h-auto"
                >
                    <div className="card-header">
                        <div className="card-title font-weight-bold">
                            Edit Beneficiary Data
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="mt-2">Beneficiary</h5>

                        <div className="row">
                            <div className="col-md-3">
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-3">
                                <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-3">
                                <TextField
                                    label="Middle Name"
                                    name="middleName"
                                    value={formData.middleName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-3">
                                <Select
                                    label="Sex"
                                    name="sex"
                                    value={formData.sex}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <h5 className="mt-2">Spouse</h5>

                        <div className="row">
                            <div className="col-md-3">
                                <TextField
                                    label="Last Name"
                                    name="spouseLastName"
                                    value={formData.spouseLastName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-3">
                                <TextField
                                    label="First Name"
                                    name="spouseFirstName"
                                    value={formData.spouseFirstName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>
                            <div className="col-md-3">
                                <TextField
                                    label="Middle Name"
                                    name="spouseMiddleName"
                                    value={formData.spouseMiddleName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </div>

                            <div className="col-md-3">
                                <Select
                                    label="Sex"
                                    name="spouseSex"
                                    value={formData.spouseSex}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <h5 className="mt-1">Place of Origin</h5>
                                <Select
                                    label="Point Of Origin"
                                    name="pointOfOrigin"
                                    value={formData.pointOfOrigin.id || ""}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {pointOfOriginOptions.map((origin) => (
                                        <MenuItem
                                            key={origin.id}
                                            value={origin.id}
                                        >
                                            {origin.projectName}
                                            {" - "}
                                            {origin.areaClassification}
                                            {" - "}
                                            {origin.specificClassification}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="col-md-6 mt-3">
                                <h5 className="mt-1">Resettlement Site</h5>
                                <Select
                                    label="Resettlement Site"
                                    name="resettlementSite"
                                    value={formData.resettlement.id || ""}
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    {resettlementSiteOptions.map((origin) => (
                                        <MenuItem
                                            key={origin.id}
                                            value={origin.id}
                                        >
                                            {origin.resettlementSiteName}
                                            {" - "}
                                            {origin.chargeability}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/* <TextField
                            label="Point of Origin - City"
                            name="pointOfOrigin.cityAddr"
                            value={formData.pointOfOrigin.cityAddr}
                            onChange={(event) =>
                                handleNestedChange(
                                    event,
                                    "pointOfOrigin.cityAddr"
                                )
                            }
                            fullWidth
                        />
                        <TextField
                            label="Point of Origin - Barangay"
                            name="pointOfOrigin.barangayAddr"
                            value={formData.pointOfOrigin.barangayAddr}
                            onChange={(event) =>
                                handleNestedChange(
                                    event,
                                    "pointOfOrigin.barangayAddr"
                                )
                            }
                            fullWidth
                        /> */}
                        {/* ... Similar structure for resettlement fields ... */}
                    </div>
                    <div className="card-footer">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit2}
                            fullWidth
                        >
                            Create Entry
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: "800px",
                        backgroundColor: "#fff", // Or a suitable color for `bgcolor: "background.paper"`
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: "16px",
                        overflow: "hidden",
                    }}
                    className="modal-viewInfo card userModal h-auto"
                >
                    <div className="card-header">
                        <div className="card-title font-weight-bold">
                            Update Data
                        </div>
                    </div>
                    <div className="card-body">
                        <div>
                            <h5 className="font-weight-bold mb-0">
                                {modalData.lastName}, {modalData.firstName}{" "}
                                {modalData.middleName}
                            </h5>
                            <ul className="list-group list-group-unbordered mb-3">
                                <li className="list-group-item border-top-0 pt-1 text-sm">
                                    {/* <span>
                                        Sequence No. &nbsp;{" "}
                                        <b>{modalData.sequenceNo}</b>
                                    </span> */}
                                    <span className="float-right">
                                        Control No. &nbsp;{" "}
                                        <b>{modalData.controlNumber}</b>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <label className="mb-0">Date Relocated</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DesktopDatePicker
                                        className="w-100"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        disabled={accessLevel != 1}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                            <label className="mb-0 mt-3">Status</label>
                            <FormControl size="small" fullWidth>
                                <Select
                                    disabled={accessLevel != 1}
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <MenuItem value="">Select Status</MenuItem>
                                    <MenuItem value="Issued">Issued</MenuItem>
                                    <MenuItem value="Cancelled">
                                        Cancelled
                                    </MenuItem>
                                    <MenuItem value="Deferred">
                                        Deferred
                                    </MenuItem>
                                    <MenuItem value="Spoiled">Spoiled</MenuItem>
                                </Select>
                            </FormControl>

                            <label className="mb-0 mt-3">Status of Award</label>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={statusOfAward}
                                    onChange={handleStatusOfAwardChange}
                                    disabled={
                                        status !== "Issued" || accessLevel != 1
                                    }
                                >
                                    <MenuItem value="">
                                        Select Status Of Award
                                    </MenuItem>
                                    <MenuItem value="Not Awarded">
                                        Not Awarded
                                    </MenuItem>
                                    <MenuItem value="Awarded">Awarded</MenuItem>
                                </Select>
                            </FormControl>

                            <label className="mb-0 mt-3">Remarks</label>
                            <Textarea
                                minRows={2}
                                onChange={handleEpRemarksChange}
                                variant="outlined"
                                value={epRemarks}
                                disabled={accessLevel != 1}
                            />

                            <label className="mb-0 mt-3">RDSD Remarks</label>
                            <Textarea
                                minRows={2}
                                value={rdsdRemarks}
                                onChange={handleRdsdRemarksChange}
                                variant="outlined"
                                disabled={accessLevel != 0}
                            />
                        </div>
                    </div>

                    <div className="card-footer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="w-100 text-dark"
                            size="large"
                            onClick={handleSubmit}
                            style={{
                                backgroundColor: "#2be0a9",
                                borderRadius: "50px",
                            }}
                        >
                            SAVE CHANGES
                        </Button>
                    </div>
                </div>
            </Modal>

            <div className="content-wrapper pl-2 pr-2 pb-3">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>Entry Pass Status</h1>
                            </div>
                            <div
                                className="col-sm-6"
                                style={{ textAlign: "right" }}
                            >
                                <Button
                                    variant="contained" // Other options include "outlined" or "text"
                                    color="primary" // You can use different colors like "secondary"
                                    startIcon={<BsDownload />} // Adds an icon at the start of the button
                                    onClick={downloadExcel}
                                    style={{
                                        textTransform: "none", // Prevents the button text from being all uppercase
                                        padding: "0.5rem 1rem",
                                        fontSize: "1rem",
                                    }}
                                >
                                    Download as xlsx
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pl-2 pr-2">
                    <div className="container-fluid">
                        <div className="mt-3">
                            <div style={{ display: "flex" }}>
                                <p className="pt-2 mr-2">Search:</p>
                                <TextField
                                    placeholder="Search here"
                                    className="mb-3"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    size="small"
                                    InputLabelProps={{ style: labelStyle }}
                                    type="text"
                                />
                                <div
                                    style={{
                                        position: "absolute", // Positions the div absolutely
                                        right: "2%", // Aligns to the right
                                        top: "14%", // Adjust to position vertically as needed
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <p className="pt-2 mr-2">
                                        Filter by Field:
                                    </p>
                                    <FormControl
                                        size="small"
                                        variant="outlined"
                                    >
                                        <InputLabel>Filter Field</InputLabel>
                                        <Select
                                            value={filterField}
                                            onChange={(e) =>
                                                setFilterField(e.target.value)
                                            }
                                            style={{
                                                width: "250px", // Adjust width to your preference
                                            }}
                                            label="Filter Field"
                                        >
                                            <MenuItem value="controlNumber">
                                                Control Number
                                            </MenuItem>
                                            <MenuItem value="firstName">
                                                First Name
                                            </MenuItem>
                                            <MenuItem value="lastName">
                                                Last Name
                                            </MenuItem>
                                            <MenuItem value="middleName">
                                                Middle Name
                                            </MenuItem>
                                            <MenuItem value="relocationDate">
                                                Relocation Date
                                            </MenuItem>
                                            <MenuItem value="epPrintStatus">
                                                EP Print Status
                                            </MenuItem>
                                            <MenuItem value="statusOfAward">
                                                Status of Award
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div
                                className="card elevation-2"
                                style={{
                                    borderTop: "4px solid #292726",
                                    borderRadius: "15px",
                                }}
                            >
                                <div className="card-header">
                                    <h3 className="card-title font-weight-bold">
                                        List of Beneficiaries with Printed Entry
                                        Pass
                                    </h3>
                                </div>

                                <div
                                    className="card-body table-responsive p-0"
                                    style={{ height: "65vh" }}
                                >
                                    <table className="table table-head-fixed bg-white table-hover table-bordered text-wrap">
                                        <thead>
                                            <tr>
                                                <th>Seq No.</th>
                                                <th>Control No.</th>
                                                <th>Last Name</th>
                                                <th>First Name</th>
                                                <th>Middle Name</th>
                                                <th>Date Relocated</th>
                                                <th>Status</th>
                                                <th>Status of Award</th>
                                                <th>Remarks</th>
                                                <th>RDSD Remarks</th>
                                                <th className="text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {Array.isArray(filteredData) &&
                                            filteredData.length > 0
                                                ? filteredData.map(
                                                      (item, index) => (
                                                          <tr
                                                              key={index}
                                                              className="td-hover"
                                                          >
                                                              <td className="text-middle">
                                                                  {index + 1}
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.controlNumber
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.lastName
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.firstName
                                                                  }{" "}
                                                                  {item.suffix &&
                                                                      item.suffix}
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.middleName
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.epReleaseDate
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.epPrintStatus
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.statusOfAward
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.epRemarks
                                                                  }
                                                              </td>
                                                              <td className="text-middle">
                                                                  {
                                                                      item.rdsdRemarks
                                                                  }
                                                              </td>
                                                              <td className="text-center p-0">
                                                                  <Tooltip title="View Info">
                                                                      <IconButton>
                                                                          <a
                                                                              href={`/#/beneficiary-info/${item.id}`}
                                                                          >
                                                                              <RemoveRedEyeRoundedIcon
                                                                                  className="text-lg"
                                                                                  style={{
                                                                                      color: "#34a8ff",
                                                                                  }}
                                                                              />
                                                                          </a>
                                                                      </IconButton>
                                                                  </Tooltip>
                                                                {accessLevel == 1 ? (
                                                                  <Tooltip title="Edit Beneficiary Details">
                                                                      <IconButton
                                                                          onClick={() =>
                                                                              fetchEditBeneficiary(
                                                                                  item.id
                                                                              )
                                                                          }
                                                                      >
                                                                          <EditNoteIcon
                                                                              className="text-lg"
                                                                              style={{
                                                                                  color: "#34a8ff",
                                                                              }}
                                                                          />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                                  ):null}
                                                                  
                                                                  <Tooltip title="Update Data">
                                                                      <IconButton
                                                                          onClick={() =>
                                                                              fetchOneBeneficiary(
                                                                                  item.id
                                                                              )
                                                                          }
                                                                      >
                                                                          <BorderColorIcon
                                                                              className="text-lg"
                                                                              style={{
                                                                                  color: "#2be0a9",
                                                                              }}
                                                                          />
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </td>
                                                          </tr>
                                                      )
                                                  )
                                                : null}
                                        </tbody>
                                    </table>
                                </div>

                                <div
                                    className="card-footer"
                                    style={{
                                        borderBottomLeftRadius: "15px",
                                        borderBottomRightRadius: "15px",
                                    }}
                                >
                                    {/* <div className="row cardFooterCenter">
                                        <div className="col-sm-6">
                                            <label>1</label>{" "}
                                            <span className="text-muted">
                                                of
                                            </span>{" "}
                                            <label>3</label>
                                            &nbsp;
                                            <IconButton>
                                                <ArrowBackIosIcon className="text-md pl-1" />
                                            </IconButton>
                                            <IconButton>
                                                <ArrowForwardIosRoundedIcon className="text-md pl-1" />
                                            </IconButton>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </div>
    );
};

export default EPstatus;
