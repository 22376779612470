// AccessDenied.jsx
import React from "react";
import { Link } from "react-router-dom";

const AccessDenied = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h1>Access Denied</h1>
            <p>You do not have permission to view this page.</p>
            <Link
                to="/home"
                style={{ textDecoration: "none", color: "#007bff" }}
            >
                Go to Home
            </Link>
        </div>
    );
};

export default AccessDenied;
